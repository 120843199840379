import React, { useEffect, useRef } from 'react'
import { Link } from "gatsby"
import parse from 'html-react-parser';
import isEqual from 'react-fast-compare'
import { useIsVisible } from 'react-is-visible'

import { dataStore, mapStore, pageStore, userStore } from "state/store-zustand";

import CardMenu from 'components/elements/cards/menu'

function Card({
  category,
  image,
  heading,
  headerHeight,
  vibes,
  children,
  isFeatured = false,
  isTitleCard = false,
  link = undefined,
  onClick,
  onHover,
  onVisible,
  orientation = 'horizontal',
  place, // Fixme: Can be place or event; make that clearer from name
  price = null,
  size = 'base',
  slug,
  showAddress = false,
  showCategory = true,
  showImage = true,
  showDescription = false,
  showNote = false,
  style = 'basic',
  title,
  viewInMap = false,
  ...props
}) {
  const compact = pageStore((state) => state.compact)
  const embedded = pageStore((state) => state.embedded)
  const showCardMenu = pageStore((state) => state.showCardMenu)
  const theme = pageStore((state) => state.theme)
  const makeLinkFromTheme = pageStore((state) => state.makeLinkFromTheme)
  const themeObject = pageStore((state) => state.themeObject)
  const placesScrolling = dataStore((state) => state.placesScrolling)
  const isLoggedIn = userStore((state) => state.isLoggedIn)


  const storyScroll = mapStore((state) => state.storyScroll)
  const currentMapLocation = mapStore((state) => state.mapLocationCurrent)
  const setCurrentMapLocation = mapStore((state) => state.setMapLocationCurrent)

  // Handle scroll in parent context
  // If Story Scroll is enabled, update map on scroll
  const cardRef = React.useRef()
  useEffect(() => {
    const { offsetTop } = cardRef?.current
    const bounds = cardRef.current.getBoundingClientRect()

    const onScroll = (event) => {
      const parentOffsetTop = typeof (headerHeight) === 'string'
        ? Number(headerHeight.replace('px', ''))
        : headerHeight

      const distFromTop = bounds?.top
      const distFromParentTop = bounds?.top - parentOffsetTop

      //console.log('DEBUG storyScroll:  distFromTop, distFromParentTop', distFromTop, distFromParentTop);

      if (distFromParentTop > 10 && distFromParentTop < 300) {
        console.log('TODO: handle scroll ', slug, bounds?.top, offsetTop);
        // Pan map to place
        const hasChanged = !isEqual(place, currentMapLocation)
        if (hasChanged) setCurrentMapLocation(place)
      }
    }

    // TODO: handle scroll in parent context
    if (storyScroll) {
      //window.addEventListener('scroll', onScroll);
    }

  }, [placesScrolling])

  const isVisible = useIsVisible(cardRef)


  useEffect(() => {
    if (isVisible) {
      if (onVisible) {
        onVisible(slug, place)
      }
    }
  }, [isVisible])


  const handleClick = (event) => {
    //console.log('handleClick ', slug, place);
    // CHeck if the user click the menu ref
    event.preventDefault()
    event.stopPropagation()

    if (event.target.id === 'long-button') return
    if (onClick) onClick(slug, place)
  }

  const handleHover = (event, place) => {
    //console.log('handleHover ', event, cardRef);
    if (onHover) onHover(event, place)
  }

  const handleScroll = (event) => {
    console.log('window handleScroll ', event, cardRef);
  }

  const handleMenuClose = () => {
    setContextMenu(null);
  }

  const cardClass = `sing-card o-${orientation} s-${style} ${isFeatured ? 'featured' : ''} ${compact ? 'compact' : ''}`

  const addressText = place?.properties?.address
  const address = showAddress && addressText
    ? (
      <span className='address' style={{ fontSize : '1.4rem', paddingBottom: '0.4rem', display: 'inline-block' }}>
        {addressText}
      </span>
    )
    : null


  // TODO: Combine with components/post/apiCard
  const descText = place?.properties?.description
  const noteClass = showNote ? 'note-show' : 'note-hide'
  const descHTML = document.createElement('div');
  descHTML.innerHTML = descText
  const noteFound = descHTML.getElementsByClassName('note')
  const noteHTML = noteFound.length > 0 ? noteFound[0].outerHTML : null
  const note = showNote && noteHTML ? (
    <div className={`note ${noteClass}`} style={{ paddingBottom : `0.8rem` }}>
      {parse(noteHTML)}
    </div>
  ) : null

  const description = showDescription && descText
    ? (
      <div className={`description truncate ${noteClass}`}>
        {parse(descText)}
      </div>
    )
    : null

  const titleHtml = typeof (title) == 'string' ? parse(title) : title

  const categoryLabel = category
    ? category.replace('&amp;', '&')
    : null

  const categoryHtml = categoryLabel
    ? (
      <span className='category'>
        {categoryLabel}
      </span>
    )
    : null

  const cardLayoutVertical = (
    <>
      {image}
      <div className="sing-card-bottom">
        {categoryHtml}
        {viewInMap}
        {price}
        <span className='title'>{titleHtml}</span>
        {address}
        {note}
        {description}
        {vibes}
      </div>
    </>
  )


  // TODO: add option to show/hide image
  const columnImage = (
    <div className={`column ${compact ? 'is-3' : 'is-4'} image is-flex-tablet`}>
      {image}
    </div>
  )


  const columnSize = compact ? "is-9" : "is-8";
  const columnDetails = (
    <div className={`column ${columnSize} text is-flex-tablet`}>
      <div className="inner-column sing-card-bottom">
        {categoryHtml}
        {viewInMap}
        {price}
        <span className='title'>{titleHtml}</span>
        {address}
        {note}
        {description}
        {vibes}
      </div>
    </div>
  );

  const cardLayoutHorizontal = (
    <div className={`columns is-flex-tablet is-gapless ${compact ? 'compact' : null}`}>
      {showImage ? columnImage : null}
      {columnDetails}
    </div>
  )

  const cardInnerClass = `sing-card-inner ${compact ? 'compact' : ''}`

  const CardLink = ({
    link,
    children,
    handleContextMenu,
    ...props
  }) => {

    link = embedded
      ? makeLinkFromTheme({link, slug, theme})
      : link

    // Handle link when embeded
    return embedded || compact
      ? <a
          className={cardInnerClass}
          href={link}
          onContextMenu={handleContextMenu}
          target={embedded ? '_blank' : '_self'}>
          {children}
        </a>
      : link
        ? <Link
          className={cardInnerClass}
            onContextMenu={handleContextMenu}
            to={link ? link : '/'}
            state={{ place: place }} >
            {children}
          </Link>
        : <a
            className={cardInnerClass}
            onContextMenu={handleContextMenu}>
            {children}
          </a>
  }

  const [contextMenu, setContextMenu] = React.useState(null)

  const handleContextMenu = (event) => {
    event.preventDefault()
    //console.log('handleContextMenu ', event);
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    )
  }

  const showEventMenu = showCardMenu && place?.card_type == 'event'
  const showPlaceMenu = showCardMenu && place?.card_type == 'place'

  const card = (
    <CardLink
      key={link}
      link={link}
      onContextMenu={handleContextMenu}
      handleContextMenu={handleContextMenu}
      style={{
        cursor: 'context-menu',
        position: 'relative'
      }} >

      {isLoggedIn
        ? <CardMenu
            showEventMenu={showEventMenu}
            showPlaceMenu={showPlaceMenu}
            contextMenu={contextMenu}
            handleMenuClose={handleMenuClose}
            card={place} />
        : null
      }

      {orientation == 'vertical'
        ? cardLayoutVertical
        : cardLayoutHorizontal
      }
    </CardLink>
  )


  return (
    <article
      ref={cardRef}
      onClick={handleClick}
      onMouseOver={(e) => handleHover(event, place)}
      onScroll={(e) => handleScroll(event, place)}
      className={cardClass}>
      {card}
    </article>
  )
}

export default Card
